<template>
	<div id="app">
		<transition name="main">
			<router-view />
		</transition>
		<notifications width="400px" group="main" />
	</div>
</template>

<script>
export default {
	name: 'app'
};
</script>

<style lang="scss">
#app {
	font-family: 'Source Sans Pro';
}

.main-enter-active,
.main-leave-active {
	transition: opacity 0.5s;
}
.main-enter,
.main-leave-to {
	opacity: 0;
}
</style>
