/* eslint-disable */
import axios from 'axios';
import Vue from 'vue';

const state = {
	projects: [],
	projectsLoaded: false,
	loggedIn: false
};

const getters = {
	projects: (state) => state.projects,
	projectsLoaded: (state) => state.projectsLoaded
};

const actions = {
	async login({ commit }, password) {
		try {
			await axios.post('main/login.php', { password });
			commit('login');
			return true;
		} catch (err) {
			return false;
		}
	},
	async getAllProjects({ commit }) {
		try {
			var res = await axios.get('project/getall.php');
			commit('updateProjects', res.data);
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async getSurveysByProject({ commit }, projectID) {
		try {
			var res = await axios.get('survey/getbyproject.php', {
				params: { id: projectID }
			});
			commit('updateSurveys', { projectID, surveys: res.data });
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async getPagesBySurvey({ commit }, { projectID, surveyID }) {
		try {
			var res = await axios.get('page/getbysurvey.php', {
				params: { id: surveyID }
			});
			commit('updatePages', { projectID, surveyID, pages: res.data });
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async getElementsByPage({ commit }, { projectID, surveyID, pageID }) {
		try {
			var res = await axios.get('element/getbypage.php', {
				params: { id: pageID }
			});
			commit('updateElements', {
				projectID,
				surveyID,
				pageID,
				elements: res.data
			});
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async addProject({ commit }, name) {
		try {
			var res = await axios.post('project/create.php', { name });
			commit('addProject', res.data);
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async deleteProject({ commit }, projectID) {
		try {
			await axios.delete('project/delete.php', { params: { id: projectID } });
			commit('deleteProject', projectID);
			return true;
		} catch (err) {
			return false;
		}
	},
	async editProject({ commit }, { name, projectID }) {
		try {
			var res = await axios.put('project/changename.php', {
				id: projectID,
				name
			});
			commit('editProject', { name: res.data.name, projectID });
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async addSurvey({ commit }, { projectID, name, information, phonenumber }) {
		try {
			var res = await axios.post('project/addsurvey.php', {
				id: projectID,
				name,
				information,
				phonenumber
			});
			commit('addSurvey', { projectID, survey: res.data });
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async deleteSurvey({ commit }, { projectID, surveyID }) {
		try {
			await axios.delete('project/removesurvey.php', {
				params: { id: surveyID }
			});
			commit('deleteSurvey', { projectID, surveyID });
			return true;
		} catch (err) {
			return false;
		}
	},
	async changeSurveyData(
		{ commit },
		{ projectID, surveyID, name, information, phonenumber }
	) {
		try {
			var res = await axios.put('survey/changedata.php', {
				id: surveyID,
				name,
				information,
				phonenumber
			});
			commit('editSurvey', {
				projectID,
				surveyID,
				name: res.data.name,
				information: res.data.information,
				phonenumber: res.data.phonenumber
			});
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async addPage({ commit }, { projectID, surveyID }) {
		try {
			var res = await axios.post('survey/addpage.php', { id: surveyID });
			commit('addPage', { projectID, surveyID, page: res.data });
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async deletePage({ commit }, { projectID, surveyID, pageID }) {
		try {
			await axios.delete('survey/removepage.php', { params: { id: pageID } });
			commit('deletePage', { projectID, surveyID, pageID });
			return true;
		} catch (err) {
			return false;
		}
	},
	async changePageData({}, { pageID, title, data }) {
		try {
			var res = await axios.put('page/changedata.php', {
				id: pageID,
				title,
				data
			});
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async initElement({ commit }, { projectID, surveyID, pageID, elementType }) {
		try {
			var res = await axios.post('page/initelement.php', {
				id: pageID,
				elementType
			});
			commit('initElement', {
				projectID,
				surveyID,
				pageID,
				elementType: res.data.elementType,
				elementData: res.data.elementData
			});
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async addElement({ commit }, { projectID, surveyID, pageID }) {
		try {
			var res = await axios.post('page/addelement.php', { id: pageID });
			commit('addElement', { projectID, surveyID, pageID, element: res.data });
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async deleteElement({ commit }, { projectID, surveyID, pageID, elementID }) {
		try {
			await axios.delete('page/deleteelement.php', {
				params: { id: elementID }
			});
			commit('deleteElement', { projectID, surveyID, pageID, elementID });
			return true;
		} catch (err) {
			return false;
		}
	},
	async changeElementData({}, { elementID, data, icon }) {
		try {
			var res = await axios.put('element/changedata.php', {
				id: elementID,
				data,
				icon
			});
			return res.data;
		} catch (err) {
			return false;
		}
	},
	async deleteSelection({ commit }, { projectID, surveyID, pageID }) {
		try {
			await axios.delete('page/deleteselection.php', {
				params: { id: pageID }
			});
			commit('deleteSelection', { projectID, surveyID, pageID });
			return true;
		} catch (err) {
			return false;
		}
	}
};

const mutations = {
	login: (state) => {
		state.loggedIn = true;
	},
	updateProjects: (state, projects) => {
		state.projects = projects;
		state.projectsLoaded = true;
	},
	updateSurveys: (state, { projectID, surveys }) => {
		var project = state.projects.find((x) => x.id == projectID);

		project.surveys = surveys;
	},
	updatePages: (state, { projectID, surveyID, pages }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);

		survey.pages = pages;
	},
	updateElements: (state, { projectID, surveyID, pageID, elements }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);
		var page = survey.pages.find((x) => x.id == pageID);

		page.elementData = elements;
	},
	addProject: (state, project) => {
		state.projects.push(project);
	},
	deleteProject: (state, projectID) => {
		state.projects.splice(
			state.projects.findIndex((x) => x.id == projectID),
			1
		);
	},
	editProject: (state, { name, projectID }) =>
		(state.projects.find((x) => x.id == projectID).name = name),
	addSurvey: (state, { projectID, survey }) =>
		state.projects.find((x) => x.id == projectID).surveys.push(survey),
	deleteSurvey: (state, { projectID, surveyID }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var surveyIndex = project.surveys.findIndex((x) => x.id == surveyID);

		project.surveys.splice(surveyIndex, 1);
	},
	editSurvey: (
		state,
		{ projectID, surveyID, name, information, phonenumber }
	) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);

		survey.name = name;
		survey.information = information;
		survey.phonenumber = phonenumber;
	},
	addPage: (state, { projectID, surveyID, page }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);

		survey.pages.push(page);
	},
	deletePage: (state, { projectID, surveyID, pageID }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);
		var pageIndex = survey.pages.findIndex((x) => x.id == pageID);

		survey.pages.splice(pageIndex, 1);
	},
	initElement: (
		state,
		{ projectID, surveyID, pageID, elementType, elementData }
	) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);
		var page = survey.pages.find((x) => x.id == pageID);

		page.elementData = elementData;
		page.elementType = elementType;
	},
	addElement: (state, { projectID, surveyID, pageID, element }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);
		var page = survey.pages.find((x) => x.id == pageID);

		page.elementData.push(element);
	},
	deleteElement: (state, { projectID, surveyID, pageID, elementID }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);
		var page = survey.pages.find((x) => x.id == pageID);

		if (page.elementType == 1) {
			var elementIndex = page.elementData.findIndex((x) => x.id == elementID);
			page.elementData.splice(elementIndex, 1);
		}
	},
	deleteSelection: (state, { projectID, surveyID, pageID }) => {
		var project = state.projects.find((x) => x.id == projectID);
		var survey = project.surveys.find((x) => x.id == surveyID);
		var page = survey.pages.find((x) => x.id == pageID);

		page.elementType = null;
		page.elementData = null;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
