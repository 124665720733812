import Vue from 'vue';
import App from './App.vue';

import { setApiUrl, routes } from './config';

console.log(Vue);

import VueRouter from 'vue-router';
import Vuex from 'vuex';

import CKEditor from '@ckeditor/ckeditor5-vue';
import Notifications from 'vue-notification';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import 'vue-easytable/libs/theme-default/index.css';
import VueEasytable from 'vue-easytable';

library.add(fab, fas, far);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(CKEditor);
Vue.use(Notifications);
Vue.use(VueEasytable);

Vue.config.productionTip = true;

Vue.config.ignoredElements = ['umfrage-tool'];

// API-Link setzen
setApiUrl();

// Router konfigurieren
const router = new VueRouter({
	routes
});

// Vuex konfigurieren
import main from './store/modules/main';

const store = new Vuex.Store({
	modules: {
		main
	}
});

router.beforeEach((to, from, next) => {
	if (to.name !== 'Login' && !store.state.main.loggedIn) {
		next({ name: 'Login' });
	} else {
		next();
	}
});

// Vue erstellen
new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
