import { defaults } from 'axios';
export { setApiUrl, routes };

var selectedApiUrl = 'default';

const apiUrlDev = 'http://localhost/api/';
const apiUrlProd = 'https://lead-funnel.myc3.com/php/api/';

function setApiUrl() {
	if (selectedApiUrl == 'dev') defaults.baseURL = apiUrlDev;
	else if (selectedApiUrl == 'prod') defaults.baseURL = apiUrlProd;
	else if (process.env.NODE_ENV == 'development') defaults.baseURL = apiUrlDev;
	else defaults.baseURL = apiUrlProd;

	defaults.withCredentials = true;
}

const routes = [
	{ name: 'NotFound', path: '*', redirect: '/login', meta: { nav: false } },
	{
		name: 'Login',
		path: '/login',
		component: () => import('./pages/Login'),
		meta: { nav: false }
	},
	{
		name: 'Projekte',
		path: '/projekte',
		component: () => import('./pages/Projekte'),
		meta: { nav: true }
	},
	{
		name: 'Editor',
		path: '/editor',
		component: () => import('./pages/Editor'),
		meta: { nav: true }
	},
	{
		name: 'Result',
		path: '/result',
		component: () => import('./pages/Ergebnis'),
		meta: { nav: true }
	}
];
